import React, { useState } from 'react'
import { signUp } from '../Services/auth'
import { Link } from 'react-router-dom'
import '../css/Login.css'

export default function Login() {
  const [form, setForm] = useState({ Name: '', Email: '', Password: '' })

  // Create a new user
  const handleSubmit = (e) => {
    e.preventDefault()
    let email = form.Email.toLowerCase().trim()
    let password = form.Password
    let displayName = form.Name
    signUp(displayName, email, password)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm((prevState) => ({ ...prevState, [name]: value }))
  }

  const formField = (type, text, changeEvent, value) => {
    return (
      <label className='label'>
        {text}
        <input
          type={type}
          className='input'
          name={text}
          id={text}
          value={value}
          onChange={changeEvent}
        />
      </label>
    )
  }

  return (
    <div className='loginContainer'>
      <h3>Sign up</h3>
      <br />
      <form>
        {formField('text', 'Name', handleChange, form.name)}
        <br />
        {formField('text', 'Email', handleChange, form.email)}
        <br />
        {formField('password', 'Password', handleChange, form.password)}
        <br />
        <div className='createAccount'>
          <input type='Submit' className='button' onClick={handleSubmit} />
        </div>
        <div className='createAccount'>
          <Link to='/login'>Already signed up? Login!</Link>
        </div>
      </form>
    </div>
  )
}
