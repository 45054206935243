export const BlendSpacer = () => {
  return (
    <svg
      width='15'
      height='16'
      viewBox='0 0 15 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 0H16C12.6061 0 8.54486 0.665912 4.84848 4.35484C1.1521 8.04377 0.161615 11.6129 0 15V0Z'
        fill='#5339ad'
      />
    </svg>
  )
}
