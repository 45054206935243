import React, { useState, useEffect } from 'react'
import { FaGoogle, FaFacebook } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { signIn, signInWithFacebook, signInWithGoogle } from '../Services/auth'
import { onAuthStateChanged, getAuth } from 'firebase/auth'
import '../css/Login.css'

export default function Login() {
  const [form, setForm] = useState({ email: '', password: '' })
  const [user, setUser] = useState(null)
  const auth = getAuth()
  const navigate = useNavigate()

  // monitor auth status function
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
        navigate('/home')
      } else {
        setUser(null)
      }
    })
  }, [])

  // Sign in submit handler
  const handleSubmit = (e) => {
    e.preventDefault()
    let email = form.email.toLowerCase().trim()
    let password = form.password

    // User name and password click handler
    signIn(email, password)
  }

  // Google click handler
  const googleSignIn = () => {
    signInWithGoogle()
  }

  // Facebook click handler
  const facebookSignIn = () => {
    signInWithFacebook()
  }

  const handleChange = (e) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  return (
    <div className='loginContainer'>
      <h3>Login</h3>
      <form className='form'>
        <label className='label'>
          Email
          <br />
          <input
            type='text'
            className='input'
            name='email'
            id='email'
            value={form.user}
            onChange={handleChange}
          />
        </label>
        <br />
        <label className='label'>
          Password
          <br />
          <input
            type='password'
            className='input'
            name='password'
            id='password'
            value={form.password}
            onChange={handleChange}
          />
        </label>
        <br />
        <div className='createAccount'>
          <input type='Submit' className='button' onClick={handleSubmit} />
        </div>
      </form>
      <div className='loginButtons'>
        <button className='login_button button--wide button--facebook'>
          <FaFacebook className='loginIcon' onClick={facebookSignIn} />
          Login with facebook
        </button>
        <button
          className='login_button button--wide button--google'
          onClick={googleSignIn}
        >
          <FaGoogle className='loginIcon' />
          Login with google
        </button>
        <div className='createAccount'>
          <Link to='/Signup'>or Create an account</Link>
        </div>
      </div>
    </div>
  )
}
