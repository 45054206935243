import React from 'react'
import { useState, useEffect } from 'react'
import '../css/Stopwatch.css'
import { v4 } from 'uuid'
import { useAuthState } from 'react-firebase-hooks/auth'
import { db, getDatabase, auth } from '../Services/firebase'
import { push, set, ref, onValue } from 'firebase/database'

function getReadout(time) {
  let minutes = time / 1000 / 60
  let seconds = time / 1000 - Math.floor(minutes) * 1000
  let milliseconds = time - Math.floor(seconds) * 1000

  let minutes2 = Math.floor(minutes).toString()
  seconds = Math.floor(seconds).toString().padStart(2, '0')
  milliseconds = Math.floor(milliseconds)
    .toString()
    .slice(0, 2)
    .padStart(2, '0')

  let readout = (minutes2 + ':' + seconds + ':' + milliseconds).split('')
  return readout
}

function Stopwatch({ currentScramble, setTimeStatus, setTimerActive }) {
  const [time, setTime] = useState(0)
  const [elapsed, setElapsed] = useState(0)
  const [isRunning, setIsRunning] = useState(false)
  const [user, loading] = useAuthState(auth)

  useEffect(() => {
    let isMounted = true

    const interval = setInterval(() => {
      if (isMounted && isRunning) {
        let now = new Date().getTime()
        setElapsed(now - time)
      }
    }, 10)

    return () => {
      isMounted = false
      clearInterval(interval)
    }
  }, [isRunning, time])

  function applyClassName(item, index, timeArray) {
    const isColon = item === ':'
    const isMinute = index < timeArray.indexOf(':')
    const isSecond =
      index > timeArray.indexOf(':') && index < timeArray.lastIndexOf(':')
    const isMillisecond = index > timeArray.lastIndexOf(':')

    if (isColon) {
      return 'readoutColon'
    } else if (isMinute) {
      return 'readoutMinute'
    } else if (isSecond) {
      return 'readoutSecond'
    } else if (isMillisecond) {
      return 'readoutMillisecond'
    }
    return ''
  }

  const handleStart = () => {
    setTimeStatus('Timer started')
    setTime(new Date().getTime())
    setIsRunning(true)
    setTimerActive(true)
    setElapsed(0)
  }

  const handleStop = () => {
    setTimeStatus('Time logged')
    setIsRunning(false)
    setTimerActive(false)
    addTime()
  }

  function formatDate(date) {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    const day = String(date.getDate()).padStart(2, '0')
    const month = monthNames[date.getMonth()]
    const year = String(date.getFullYear()).slice(-2)
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    return `${day} ${month} ${year} | ${hours}:${minutes}`
  }

  // PUSH new time to DB
  const addTime = () => {
    const now = new Date()

    const timeRef = ref(db, 'users/' + user?.uid + '/times')
    const attemptDate = formatDate(now)
    const theTime = {
      elapsed,
      attemptDate,
      currentScramble,
    }
    push(timeRef, theTime)
  }

  return (
    <div className='stopwatch'>
      <button
        className='startTimerButton'
        onClick={isRunning ? handleStop : handleStart}
      >
        <div className='timerReadout'>
          {getReadout(elapsed).map((item, index, array) => (
            <div
              key={v4()}
              className={`readoutCharacter ${applyClassName(
                item,
                index,
                array
              )}`}
            >
              {item}
            </div>
          ))}
        </div>
      </button>
    </div>
  )
}

export { Stopwatch, getReadout }
