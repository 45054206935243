import React from 'react'
import { useState, useEffect } from 'react'
import { onAuthStateChanged, getAuth } from 'firebase/auth'
import { Routes, Route } from 'react-router-dom'
import Home from './Home'
import Login from './Login'
import Signup from './Signup'
import Stats from './Stats'
import Algorithms from './Algorithms'
import Timer from './Timer'
import Trainer from './Trainer'
import AccountPage from './AccountPage'

const Main = () => {
  const auth = getAuth()
  const [user, setUser] = useState(null)

  // monitor auth status function
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
      } else {
        setUser(null)
      }
    })
  }, [])

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='login' element={<Login />} />
      <Route path='home' element={<Home />} />
      <Route path='signup' element={<Signup />} />
      <Route path='home' element={<Home />} />
      <Route path='stats' element={<Stats />} />
      <Route path='algorithms' element={<Algorithms />} />
      <Route path='trainer' element={<Trainer />} />
      <Route path='timer' element={<Timer />} />
      <Route path='account' element={<AccountPage />} />
    </Routes>
  )
}

export default Main
