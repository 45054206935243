import { auth } from '../Services/firebase'
import { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'

import '../css/Graph.css'

export default function Graph({ times, filter }) {
  const [user, loading] = useAuthState(auth)
  const [bars, setBars] = useState([])
  const [renderKey, setRenderKey] = useState(0)

  useEffect(() => {
    const bars = []
    if (user !== null && times) {
      let theTimes = [...times].reverse().splice(0, 15)
      let result = theTimes.map((a) => a.elapsed)
      let sorted = sortAscending(result)
      const dataSet = filter === 'Latest Times' ? result : sorted
      let normalizedData = normalizeArray(dataSet)
      const numberOfTimes = 15
      for (let i = 0; i < numberOfTimes; i++) {
        bars.push(
          <div
            key={i}
            className='graph-bar bounce-scale'
            style={{
              height: normalizedData[i],
            }}
          >
            {dataSet[i]}
          </div>
        )
      }
    }
    setBars(bars)
    setRenderKey((prevKey) => prevKey + 1)
  }, [user, times, filter])

  function sortAscending(array) {
    return array.toSorted((a, b) => a - b)
  }

  function normalizeArray(array) {
    let min = Math.min(...array)
    let max = Math.max(...array)
    let range = max - min

    return array.map((value) => ((value - min) / range) * 100)
  }

  return (
    <div className='graph-container' key={renderKey}>
      {bars}
    </div>
  )
}
