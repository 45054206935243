import { useState, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { BiMedal } from 'react-icons/bi'
import { db, auth } from '../Services/firebase'
import { ref, onValue } from 'firebase/database'
import { getReadout } from './Stopwatch'
import '../css/Card2.css'
import '../css/LatestTimes.css'

function BestTimes({ status, timeFilter }) {
  const [user, loading] = useAuthState(auth)
  const [timeList, setTimeList] = useState([])

  const medalList = ['#FFD700', '#C0C0C0', '#CD7F32']

  // On mount, get the latest times
  useEffect(() => {
    let isMounted = true
    if (user !== null || status === 'Time logged') {
      const timeRef = ref(db, 'users/' + user.uid + '/times')
      onValue(timeRef, (snapshot) => {
        if (isMounted) {
          const newTimeList = []
          for (let key in snapshot.val()) {
            newTimeList.push({ ...snapshot.val()[key], id: key })
          }
          if (timeFilter === 'Best Times') {
            setTimeList(newTimeList.sort((b, a) => a.elapsed - b.elapsed))
          } else if (timeFilter === 'Latest Times') {
            setTimeList(newTimeList)
          } else {
            setTimeList(newTimeList)
          }
        }
      })
    }
    return () => {
      isMounted = false
    }
  }, [user, status, timeFilter])

  function removeZero(values) {
    let array = Object.values(values)
    while (array[0] === '0' || array[0] === ':') {
      array.shift()
    }
    if (array.length <= 2) {
      array.push('ms')
    } else if (array.length <= 5) {
      array.push('s')
    } else {
      array.push('m')
    }
    return array
  }

  const latestTimes = () => {
    return timeList
      .slice()
      .reverse()
      .slice(0, 10)
      .map((item, index) => (
        <div className='time-row--container' key={item.id}>
          <div className='time-row'>
            <div className='time-row--left'>
              <div className='latestTimes-date'>{item.attemptDate}</div>
              <div className='latestTimes-scramble'>{item.currentScramble}</div>
            </div>
            <div className='time-row--right'>
              <div className='latestTimes-elapsed' key={item.id}>
                {removeZero(getReadout(item.elapsed))}
              </div>
              {index <= 2 && timeFilter === 'Best Times' && (
                <div
                  className='latestTimes-medal'
                  style={{ color: medalList[index] }}
                >
                  <BiMedal />
                </div>
              )}
            </div>
          </div>
          <div className='time-row--seperator'></div>
        </div>
      ))
  }

  return (
    <>
      <div className='grid-container--light grid-container--wide'>
        {latestTimes()}
      </div>
    </>
  )
}

export default BestTimes
