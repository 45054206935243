import React, { useState, useEffect } from 'react'
import { BsPlusSquareDotted } from 'react-icons/bs'
import avatar from '../assets/Avatar.png'
import '../css/AccountPage.css'

// Replace this with your Firebase configuration and import
// import firebase from './firebase';

const AccountPage = () => {
  const [userInfo, setUserInfo] = useState({
    name: 'Joe Stevenson',
    age: '17',
    country: 'Dubai',
    method: 'CFOP',
    bestTime: '14 s',
    userPicture: '',
  })

  const [bestTimeCard, setBestTimeCard] = useState(null)
  const [collection, setCollection] = useState([])

  // Replace this function with the actual function to fetch best time from Firebase
  const fetchBestTime = async () => {
    // Fetch the best time from your Firebase Realtime Database and update the state
  }

  useEffect(() => {
    fetchBestTime()
  }, [])

  const handleAddNew = () => {
    // Implement functionality to add a new Rubik's Cube to the collection
  }

  const renderUserPictureOptions = () => {
    const publicPath = process.env.PUBLIC_URL
    const avatarPath = avatar

    return <img src={avatarPath} className='user-picture' alt='User Avatar' />
  }

  const renderCollectionItems = () => {
    // Render the collection items from the database
    return (
      <>
        <div className='imageCard'></div>
        <div className='imageCard'></div>
      </>
    )
  }

  return (
    <div className='account-page'>
      <section className='user'>
        <div className='user-info'>
          <div className='user-data'>
            <p>
              <h1>{userInfo.name}</h1>
            </p>
            <p>Age: {userInfo.age}</p>
            <p>Country: {userInfo.country}</p>
            <p>3x3 Method: {userInfo.method}</p>
            <p>Best Time: {userInfo.bestTime}</p>
          </div>
          <div>{renderUserPictureOptions()}</div>
        </div>
      </section>

      <section className='times'>
        <div className='section-title'>Times</div>
        <div className='best-time-card'>
          {bestTimeCard && (
            <div>
              <p>Best Time: {bestTimeCard.bestTime}</p>
              <p>Method: {bestTimeCard.method}</p>
              <p>Time: {bestTimeCard.time}</p>
            </div>
          )}
        </div>
      </section>

      <section className='my-collection'>
        <div className='section-title'>My Collection</div>
        <div className='collection-items'>
          {renderCollectionItems()}
          <BsPlusSquareDotted className='plus' onClick={handleAddNew}>
            Add New
          </BsPlusSquareDotted>
        </div>
      </section>
    </div>
  )
}

export default AccountPage
