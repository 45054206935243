import React from 'react'
import { v4 } from 'uuid'
import '../css/AlgDisplay.css'

function AlgDisplay(alg) {
  let styledDisplay = []
  // return alg
  let splitArray = alg.alg.split(' ')
  splitArray.map((move, index) => {
    if (move.includes("'")) {
      styledDisplay.push(
        <div className='move prime' key={index + 'pr'}>
          {move}
        </div>
      )
    } else if (move.includes('2')) {
      styledDisplay.push(
        <div className='move double' key={index + 'do'}>
          {move}
        </div>
      )
    } else {
      styledDisplay.push(
        <div className='move default' key={index + 'de'}>
          {move}
        </div>
      )
    }
  })
  return styledDisplay
}

export default AlgDisplay
