import { v4 } from 'uuid'

export default function highlighting(alg) {
  /**
   * Highlights the moves with a prime notation
   * @param {string} alg - Algorithm string
   * @returns {array}Array of highlighted moves
   */
  return alg.split(' ').map((x) =>
    x.includes("'") ? (
      <span key={v4()} style={{ color: '#2fbcef' }}>
        {x + ' '}
      </span>
    ) : (
      `${x + ' '}`
    )
  )
}
