import React from 'react'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ImStatsDots } from 'react-icons/im'
import { HiDatabase } from 'react-icons/hi'
import { FaRunning, FaShoppingCart, FaUniversity, FaUser } from 'react-icons/fa'
import { MdOutlineTimer } from 'react-icons/md'
import '../css/Sidebar.css'

export default function Sidebar() {
  const [selected, setSelected] = useState(useLocation().pathname.substring(1))

  const menuItems = [
    // { name: 'Stats', to: '/Stats', icon: ImStatsDots },
    { name: 'Timer', to: '/Timer', icon: MdOutlineTimer },
    { name: 'Algorithms', to: '/Algorithms', icon: HiDatabase },
    // { name: 'Cube', to: '/Cube', icon: FaRunning },
    { name: 'Trainer', to: '/Trainer', icon: FaUniversity },
    { name: 'Account', to: '/account', icon: FaUser },
  ]
  function handleSelect(item) {
    setSelected(item)
  }

  return (
    <div className='sidebarContainer'>
      {menuItems.map((item, index) => {
        const Icon = menuItems[index].icon
        return (
          <Link
            key={item.name}
            to={menuItems[index].to}
            className={
              selected === item.name ? 'menuIcon menuIcon--active bounce-in' : 'menuIcon'
            }
            name={item.name}
            onClick={(e) => handleSelect(item.name)}
          >
            <Icon />
          </Link>
        )
      })}
    </div>
  )
}
