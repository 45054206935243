import React, { useState, useEffect } from 'react'
import LLDisplay from '../Components/LLDisplay'
import { get, ref, onValue } from 'firebase/database'
import { db } from '../Services/firebase'
import highlighting from '../Services/highlighting'
import { IoOptions } from 'react-icons/io5'
import '../css/Trainer.css'
import OptionsMenu from '../Components/OptionsMenu'

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

function Trainer() {
  const [alg, setAlg] = useState({ alg: '', name: '' })
  const [type, setType] = useState('pll')
  const [key, setKey] = useState(0)
  const [showOptions, setShowOptions] = useState(false)

  useEffect(() => {
    let isMounted = true

    get(ref(db, type)).then((snapshot) => {
      const data = snapshot.val()
      if (data !== null) {
        if (isMounted) {
          setAlg(data[getRandomInt(data.length)])
        }
      }
    })
    return () => (isMounted = false)
  }, [])

  function handleGenerate() {
    get(ref(db, type)).then((snapshot) => {
      const data = snapshot.val()
      if (data !== null) {
        setAlg(data[getRandomInt(data.length)])
        setKey((prevKey) => prevKey + 1) // update key on alg change
      }
    })
  }
  function toggleOptions() {
    setShowOptions((prevShowOptions) => !prevShowOptions)
  }
  return (
    <div className='trainer'>
      <div className='trainer-cube' onClick={handleGenerate}>
        <LLDisplay alg={alg} shouldUpdate={true} />
      </div>
      <div className='alg-info bounce-in-right' key={key}>
        <div>{alg.name}</div>
        <div className='alg_text'>{highlighting(alg.alg)}</div>
      </div>
      <div className='trainer-options' onClick={toggleOptions}>
        <IoOptions />
      </div>
      {showOptions && <OptionsMenu />}
    </div>
  )
}

export default Trainer
