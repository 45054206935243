import React from 'react'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BlendSpacer } from '../Components/Svgs'
import { signOutUser } from '../Services/auth'
import { onAuthStateChanged, getAuth } from 'firebase/auth'
import '../css/Header.css'

export default function Header() {
  const [user, setUser] = useState()
  const location = useLocation()
  const auth = getAuth()
  const navigate = useNavigate()

  // monitor auth status function
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
      } else {
        setUser(null)
      }
    })
  }, [])

  const signOut = () => {
    signOutUser()
    navigate('/login')
  }

  const logIn = () => {
    navigate('/Login')
  }

  return (
    <div className='headerContainer'>
      <div className='headerElements'>
        <div className='info'>
          <div className='logo'>MYCUBE</div>
          <div className='greeting'>
            {user ? `Happy cubing` : `Let's cube`}
          </div>
        </div>
        <div className='auth'>
          {user ? (
            <div className='button' onClick={signOut}>
              Sign Out
            </div>
          ) : (
            location.pathname.toLowerCase() !== '/login' && (
              <div className='button' onClick={logIn}>
                LogIn
              </div>
            )
          )}
        </div>
      </div>
      <div className='blendSpacer'>
        <BlendSpacer />
      </div>
    </div>
  )
}
