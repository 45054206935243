export function getCubeInformation() {
  return { cube: buildLoop(), moves: moves, members: sideMembers }
}

const buildLoop = () => {
  let cube = {}
  for (let i = 0; i < 27; i++) {
    cube[i] = {
      inSlot: i,
      orient: {
        u: 'y',
        d: 'w',
        f: 'g',
        r: 'r',
        b: 'b',
        l: 'o',
      },
    }
  }
  return cube
}

const moves = {
  F: {
    aff: [[6, 7, 8, 17, 26, 25, 24, 15]],
  },
  B: {
    aff: [[2, 1, 0, 9, 18, 19, 20, 11]],
  },
  R: {
    aff: [[8, 5, 2, 11, 20, 23, 26, 17]],
  },
  L: {
    aff: [[0, 3, 6, 15, 24, 21, 18, 9]],
  },
  U: {
    aff: [[0, 1, 2, 5, 8, 7, 6, 3]],
  },
  D: {
    aff: [[24, 25, 26, 23, 20, 19, 18, 21]],
  },
  M: {
    aff: [[16, 25, 22, 19, 10, 1, 4, 7]],
  },
  S: {
    aff: [[3, 4, 5, 14, 23, 22, 21, 12]],
  },
  E: {
    aff: [[9, 10, 11, 14, 17, 16, 15, 12]],
  },
  r: {
    aff: [
      [8, 5, 2, 11, 20, 23, 26, 17],
      [7, 4, 1, 10, 19, 22, 25, 16],
    ],
  },
  l: {
    aff: [
      [0, 3, 6, 15, 24, 21, 18, 9],
      [16, 25, 22, 19, 10, 1, 4, 7],
    ],
  },
  x: {
    aff: [
      [8, 5, 2, 11, 20, 23, 26, 17],
      [1, 4, 7, 16, 25, 22, 19, 10],
      [9, 18, 21, 24, 15, 6, 3, 0],
    ],
  },
  y: {
    aff: [
      [0, 1, 2, 5, 8, 7, 6, 3],
      [9, 10, 11, 14, 17, 16, 15, 12],
      [21, 18, 19, 20, 23, 26, 25, 24],
    ],
  },
  z: {
    aff: [
      [6, 7, 8, 17, 26, 25, 24, 15],
      [3, 4, 5, 14, 23, 22, 21, 12],
      [11, 20, 19, 18, 9, 0, 1, 2],
    ],
  },
}

const sideMembers = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8], // White   /  Top
  [24, 25, 26, 21, 22, 23, 18, 19, 20], // Yellow  /  Bottom
  [6, 7, 8, 15, 16, 17, 24, 25, 26], // Green   /  Front
  [8, 5, 2, 17, 14, 11, 26, 23, 20], // Red     /  Right
  [2, 1, 0, 11, 10, 9, 20, 19, 18], // Blue    /  Back
  [0, 3, 6, 9, 12, 15, 18, 21, 24], // Orange  /  Left
]
