import React from 'react'
import '../css/Stats.css'

function Stats() {
  return (
    <div className='stats'>
      <div>Stats</div>
    </div>
  )
}

export default Stats
