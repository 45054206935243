// TODO - Fix X2 moves. Currently they are solving innacurately and should be put in the move list

const moves = {
  F: {
    aff: [[6, 7, 8, 17, 26, 25, 24, 15]],
  },
  B: {
    aff: [[2, 1, 0, 9, 18, 19, 20, 11]],
  },
  R: {
    aff: [[8, 5, 2, 11, 20, 23, 26, 17]],
  },
  L: {
    aff: [[0, 3, 6, 15, 24, 21, 18, 9]],
  },
  U: {
    aff: [[0, 1, 2, 5, 8, 7, 6, 3]],
  },
  D: {
    aff: [[24, 25, 26, 23, 20, 19, 18, 21]],
  },
  M: {
    aff: [[16, 25, 22, 19, 10, 1, 4, 7]],
  },
  S: {
    aff: [[3, 4, 5, 14, 23, 22, 21, 12]],
  },
  E: {
    aff: [[9, 10, 11, 14, 17, 16, 15, 12]],
  },
  r: {
    aff: [
      [8, 5, 2, 11, 20, 23, 26, 17],
      [7, 4, 1, 10, 19, 22, 25, 16],
    ],
  },
  l: {
    aff: [
      [0, 3, 6, 15, 24, 21, 18, 9],
      [16, 25, 22, 19, 10, 1, 4, 7],
    ],
  },
  x: {
    aff: [
      [8, 5, 2, 11, 20, 23, 26, 17],
      [1, 4, 7, 16, 25, 22, 19, 10],
      [9, 18, 21, 24, 15, 6, 3, 0],
    ],
  },
  y: {
    aff: [
      [0, 1, 2, 5, 8, 7, 6, 3],
      [9, 10, 11, 14, 17, 16, 15, 12],
      [21, 18, 19, 20, 23, 26, 25, 24],
    ],
  },
  z: {
    aff: [
      [6, 7, 8, 17, 26, 25, 24, 15],
      [3, 4, 5, 14, 23, 22, 21, 12],
      [11, 20, 19, 18, 9, 0, 1, 2],
    ],
  },
}
let moveGroups = [
  'R',
  'L',
  'F',
  'B',
  'U',
  'D',
  'R2',
  'L2',
  'F2',
  'B2',
  'U2',
  'D2',
  "R'",
  "L'",
  "F'",
  "B'",
  "U'",
  "D'",
]
const moveList = [
  ['R', "R'", 'R2'],
  ['L', "L'", 'L2'],
  ['F', "F'", 'F2'],
  ['B', "B'", 'U2'],
  ['U', "U'", 'U2'],
  ['D', "D'", 'D2'],
]

let initialState = {}

function buildLoop() {
  let cube = []
  for (let i = 0; i < 27; i++) {
    cube[i] = [i, [0, 1, 2, 3, 4, 5]]
  }
  return cube
}

function getRandomIntBetween(min, max) {
  // return a number from min to max - 1. Ex. 3, 9 returns 3 - 8
  return Math.floor(Math.random() * (max - min) + min)
}

function generate_scramble() {
  let scramble = []

  scramble.push(getRandomIntBetween(0, 6))

  for (let i = 0; i < 20; i++) {
    while (scramble.length !== i) {
      let move = getRandomIntBetween(0, 6)
      if (scramble[i] !== move) {
        scramble.push(move)
        break
      }
    }
  }
  // Substitute numbers for move letters
  let scramble_adj = scramble.map((item, index) => {
    return (item = moveList[item][getRandomIntBetween(0, 3)])
  })

  return JSON.stringify(scramble_adj.toString())
    .replace(/"/g, '')
    .replace(/,/g, ' ')
}

function run_algorithm(algorithm) {
  const alg = algorithm.split(' ')
  let updatedCube = JSON.parse(JSON.stringify(cubeState))

  for (let n = 0; n < alg.length; n++) {
    let element = alg[n]
    let double = element[1] === '2' ? 2 : 1

    for (let i = 0; i < double; i++) {
      let prime = element[1] ? (element[1] === "'" ? 1 : 0) : 0
      let moveSet = moves[element[0]]

      moveSet['aff'].forEach((array, i) => {
        array.forEach((member, j) => {
          let nextIndex = !prime
            ? j > 1
              ? j - 2
              : j - 2 + 8
            : j > 5
            ? j - 8 + 2
            : j + 2

          let cur = member,
            next = moveSet['aff'][i][nextIndex]
          updatedCube[cur][0] = cubeState[next][0]
          // updatedCube[cur][1] = element[1]
        })
      })
      cubeState = JSON.parse(JSON.stringify(updatedCube))
    }
  }
}

function testAlg(alg) {
  run_algorithm(alg)
  if (JSON.stringify(solvedCube) === JSON.stringify(cubeState)) {
    solved = true
    console.log('Cube solved with: ', alg)
  } else {
    solved = false
    cubeState = initialState
  }
  return solved
}

function recursion(set) {
  // Base case
  if (iteration !== 3 && solved == false) {
    count++ // How many times the recursion has run

    for (let i = 0; i < moveGroups.length; i++) {
      const blockSize = Math.pow(moveGroups.length, iteration + 1)
      // On first iteration only
      if (tree.length < moveGroups.length) {
        // TEST ALG
        tree.push(set[i])
        i === moveGroups.length - 1 && iteration++
        testAlg(tree[tree.length - 1])
        if (solved) {
          break
        }
      }
      // If on last loop, increase iteration
      else if (tree.length != blockSize && !solved) {
        // TEST ALG
        tree.push(tree[count - 2] + ' ' + moveGroups[i])
        testAlg(tree[tree.length - 1])
        if (solved) {
          break
        }
      }
      // If still in iteration, continue loop
      else {
        iteration++
        break
      }
    }
    recursion(tree)
  } else {
    return
    // Stop after n iterations ( number of moves )
  }
  return solved
}

function validateScramble(scramble) {
  return recursion(moveGroups)
}

function resetCube() {
  solved = false
}

// ************************************

// build the cube
let solvedCube = buildLoop()
let cubeState = buildLoop()

let tree = []
let count = 0
let iteration = 0
let solved = false

export default function scrambleIt(num) {
  for (let i = 0; i < num; i++) {
    resetCube()
    // Generate a potential scramble
    let scramble = generate_scramble()
    // Run the alg on a test cube
    run_algorithm(scramble)
    // Store this state
    initialState = JSON.parse(JSON.stringify(cubeState))
    // Check if state can be solved in less than 3 moves
    let solved = validateScramble(scramble)
    if (solved == true) {
      i++
      console.log('Cube can be solved in less than 3 moves')
    } else {
      return scramble
    }
  }
}
