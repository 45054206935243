import React from 'react'
import { v4 } from 'uuid'
import { getCubeInformation } from '../Services/cube'
import { useEffect, useState } from 'react'
import '../css/LLDisplay.css'
import { BsPlayCircle } from 'react-icons/bs'

let algArray = '' // global variable outside state

const LLDisplay = React.memo(
  ({ alg = '', myFilter, play, search, shouldUpdate }) => {
    const [step, setStep] = useState(null)
    const [speed, setSpeed] = useState(0)
    const [theAlg, setTheAlg] = useState([])

    const cube = getCubeInformation()
    const [cubeState, setCubeState] = useState(getCubeInformation().cube) // Get cube array using LazyState Initialization

    useEffect(() => {
      setCubeState(getCubeInformation().cube)
    }, [])

    useEffect(() => {
      setCubeState(getCubeInformation().cube)
      alg.alg !== '' && doMove(alg.alg, true)
    }, [alg.alg])

    useEffect(() => {
      let isMounted = true
      setTimeout(() => {
        if (step < theAlg.length && step != null) {
          let prime = theAlg[step][1] ? (theAlg[step][1] === "'" ? 1 : 0) : 0
          let moveSet = cube.moves[theAlg[step][0]]
          let updatedCube = { ...cubeState }
          moveSet['aff'].forEach((array, i) => {
            array.forEach((member, j) => {
              let nextIndex = !prime
                ? j > 1
                  ? j - 2
                  : j - 2 + 8
                : j > 5
                ? j - 8 + 2
                : j + 2

              let cur = member,
                next = moveSet['aff'][i][nextIndex] // find block to move into slot
              updatedCube[cur] = { ...cubeState[next] } // store new block order info
              updatedCube[cur].orient = doCycleColor(
                updatedCube[cur].orient,
                theAlg[step][0],
                prime
              ) // store new color info
            })
          })
          if (isMounted) {
            setCubeState((prevState) => ({ ...prevState, ...updatedCube }))
            setStep((prevState) => prevState + 1)
          }
        }
      }, [speed])
      return () => {
        isMounted = false
      }
    }, [step])

    function reverseItem(item) {
      // get item length
      // if item length is greater than 1 it must be ' or 2
      // if second item is ' , remove it
      // if no second item, then add '
      const len = item.length
      if (len > 1 && item[1] !== '2') {
        return item[0]
      } else if (len === 1) {
        return item[0] + "'"
      } else return item
    }

    function doMove(notation, reverse) {
      /**
       * Execute a move on the cube array
       * @param {string} notation - Move notation
       */
      algArray = notation.toString().split(' ')
      for (let i = 0; i < algArray.length; i++) {
        if (algArray[i][1] === '2') {
          algArray.splice(i + 1, 0, algArray[i][0])
          algArray[i] = algArray[i][0]
        }
        if (reverse === true) {
          algArray[i] = reverseItem(algArray[i])
        }
      }
      setTheAlg(reverse ? algArray.reverse() : algArray)
      setStep(0)
    }

    function doCycleColor(orient, direction, prime) {
      /**
       * Rotates each block in a specific direction, changing the color of the displayed faces
       * @param {array} orient - the current orientation of the block
       * @param {string} direction - the direction of rotation
       */
      const rotation = {
        // Order the colors rotate
        R: ['ufdb', 'ubdf'],
        L: ['ubdf', 'ufdb'],
        U: ['frbl', 'flbr'],
        D: ['flbr', 'frbl'],
        B: ['urdl', 'uldr'],
        F: ['uldr', 'urdl'],
        M: ['ubdf', 'ufdb'],
        S: ['uldr', 'urdl'],
        E: ['flbr', 'frbl'],
        r: ['ufdb', 'ubdf'],
        l: ['ubdf', 'ufdb'],
        x: ['ufdb', 'ubdf'],
        y: ['frbl', 'flbr'],
        z: ['uldr', 'urdl'],
      }

      let order = { ...orient } // Deep copy current color orientation
      let shift = rotation[direction][prime] // Choose color direction order

      for (let i = 0; i < 4; i++) {
        order[shift[i]] = orient[shift[(i + 1) % 4]] // Update orientation
      }

      return order
    }
    function generateSide(side) {
      /**
       * Function to build one side of a cube
       * @param {number} side - Index of the side to be builr
       *
       * @returns {JSX} - A JSX element representing the side of the cube
       */
      const members = cube.members[side]
      const sideKey = ['u', 'd', 'f', 'r', 'b', 'l']

      return (
        <div className='cube_side'>
          {members.map((block, index) => {
            const { inSlot, orient } = cubeState[block] || {}
            const col = orient ? orient[sideKey[side]] : ''

            return (
              <div
                className={`cube_face face-${index} cube_face__${col}`}
                id={inSlot}
                key={v4()}
              />
            )
          })}
        </div>
      )
    }

    function handlePlay() {
      setSpeed(100)
      doMove(alg.alg)
    }

    function generateTop() {
      // const members = cube.members[side]
      const recFilter = true
      const sideKey = ['u', 'd', 'f', 'r', 'b', 'l']
      const topOrder = [
        null,
        { id: 0, col: 'b', class: 'faceHorizontal face-6' },
        { id: 1, col: 'b', class: 'faceHorizontal face-7' },
        { id: 2, col: 'b', class: 'faceHorizontal face-8' },
        null,
        { id: 0, col: 'l', class: 'faceVertical face-2' },
        { id: 0, col: 'u', class: 'face face-0' },
        { id: 1, col: 'u', class: 'face face-1' },
        { id: 2, col: 'u', class: 'face face-2' },
        { id: 2, col: 'r', class: 'faceVertical face-0' },
        { id: 3, col: 'l', class: 'faceVertical face-5' },
        { id: 3, col: 'u', class: 'face face-3' },
        { id: 4, col: 'u', class: 'face face-4' },
        { id: 5, col: 'u', class: 'face face-5' },
        { id: 5, col: 'r', class: 'faceVertical face-3' },
        { id: 6, col: 'l', class: 'faceVertical face-8' },
        { id: 6, col: 'u', class: 'face face-6' },
        { id: 7, col: 'u', class: 'face face-7' },
        { id: 8, col: 'u', class: 'face face-8' },
        { id: 8, col: 'r', class: 'faceVertical face-6' },
        null,
        { id: 6, col: 'f', class: 'faceHorizontal face-0' },
        { id: 7, col: 'f', class: 'faceHorizontal face-1' },
        { id: 8, col: 'f', class: 'faceHorizontal face-2' },
        null,
      ]

      // Generate a grid of 25
      let gridArray = []

      let { inSlot, orient } = 0 || {}
      let col = ''

      for (let i = 0; i < 25; i++) {
        let create = topOrder[i] != null ? true : false

        create ? (inSlot = cubeState[topOrder[i].id].inSlot) : (inSlot = 0)
        create
          ? (col = cubeState[topOrder[i].id].orient[topOrder[i].col])
          : (col = {})

        gridArray.push(
          topOrder[i] != null ? (
            <div
              className={`${topOrder[i].class} cube_face__${col} ${
                recFilter & (col !== 'y') ? 'recFilter' : ''
              }`}
              id={inSlot}
              key={v4()}
            ></div>
          ) : (
            <div className='blank' key={v4()}></div>
          )
        )
      }
      return <>{gridArray}</>
    }
    return (
      <>
        <div className='lldisplay-grid'>
          {play === 'true' && (
            <div className='play_container' onClick={() => handlePlay()}>
              <BsPlayCircle className='icon' />
            </div>
          )}
          {generateTop()}
        </div>
      </>
    )
  },
  (prevProps, nextProps) => {
    return !nextProps.shouldUpdate
  }
)
export default LLDisplay
