import React from 'react'
import { db } from '../Services/firebase'
import { get, set, child, ref } from 'firebase/database'
import { BiEditAlt } from 'react-icons/bi'
import { useEffect, useState, useRef } from 'react'
import LLDisplay from '../Components/LLDisplay'
import { FixedSizeList as List } from 'react-window'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../Services/firebase'
import highlighting from '../Services/highlighting'

import '../css/Algorithms.css'

export default function Algorithms() {
  const [user, loading] = useAuthState(auth)
  const [algs, setAlgs] = useState([])
  const [type, setType] = useState('oll')
  const [search, setSearch] = useState('')
  const [myFilter, setMyFilter] = useState('oll')
  const [activeFilter, setActiveFilter] = useState('oll')
  const [activeStateFilter, setActiveStateFilter] = useState('oll')
  const [toolTipID, setToolTipID] = useState(0)
  const [tagFilters, setTagFilters] = useState([])

  const filterButtons = ['oll', 'pll', 'coll', 'zbll']
  const filterStates = ['WEAK', 'LEARNING', 'LOCKED']

  const filterColors = {
    WEAK: '#f44336',
    LEARNING: '#ffe599',
    LOCKED: '#93c47d',
  }

  const GUTTER_SIZE = 10

  function fetchCombinedData(type, search) {
    /**
     * Fetches combined data from Firebase and filters the data based on the search input
     * @param {string} type - Type of algorithm set (e.g., 'oll', 'pll', 'coll', 'zbll')
     * @param {string} search - Search input value
     * @returns {Promise<array>} - Filtered algorithms with tag colors
     */
    const typeRef = ref(db, type)
    const userTypeRef = ref(db, `${user?.uid}/${type}`)

    return Promise.all([get(typeRef), get(userTypeRef)])
      .then(([typeSnapshot, userTypeSnapshot]) => {
        const typeData = typeSnapshot.val() || {}
        const userTypeData = userTypeSnapshot.val() || {}

        return Object.values(typeData)
          .filter((alg) => alg.name.includes(search))
          .map((alg) => ({
            ...alg,
            tagColor: userTypeData[alg.name]?.tagColor || [],
          }))
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }

  useEffect(() => {
    let isMounted = true
    setAlgs([])

    fetchCombinedData(type, search).then((combinedData) => {
      if (isMounted) {
        // Apply the tag color filters
        const filteredData = combinedData.filter((alg) =>
          tagFilters.length > 0 ? tagFilters.includes(alg.tagColor) : true
        )
        setAlgs(filteredData)
      }
    })

    return () => {
      isMounted = false
    }
  }, [type, search, tagFilters])

  function handleClick(e) {
    /**
     * Handles click events for the filter buttons
     * @param {object} e - Event object
     */
    const { id } = e.target

    if (filterButtons.includes(id)) {
      setActiveFilter(id)
      setType(id)
      setMyFilter(id)
    } else if (filterStates.includes(id)) {
      const color = filterColors[id]
      if (tagFilters.includes(color)) {
        setTagFilters(tagFilters.filter((filter) => filter !== color))
      } else {
        setTagFilters([...tagFilters, color])
      }
      setActiveStateFilter((prev) => (prev === id ? '' : id))
    }
  }

  function Tip({ alg, index, handleTipEdit }) {
    const [editedTip, setEditedTip] = useState(null)

    const handleBlur = (e) => {
      const newTip = e.target.innerText || 'Click to edit tip...'
      setEditedTip(newTip)
      handleTipEdit(index, newTip)
    }

    return (
      <div
        className='tip'
        contentEditable={true}
        onBlur={handleBlur}
        suppressContentEditableWarning={true}
      >
        {editedTip || alg.tip}
        <div className='editIcon'>
          <BiEditAlt />
        </div>
      </div>
    )
  }

  const prevAlgsRef = useRef()

  useEffect(() => {
    prevAlgsRef.current = algs
  }, [algs])

  const prevAlgs = prevAlgsRef.current

  const shouldUpdateLLDisplay = (prevAlgs, algs) => {
    if (prevAlgs === algs) {
      return true
    } else {
      return false
    }
  }

  const Row = React.memo(
    ({ alg, index, style }) => {
      const [tagColor, setTagColor] = useState(alg.tagColor)
      const handleTagClick = () => {
        let newColor
        switch (tagColor) {
          case 'lightGrey': // Light grey
            newColor = '#ffe599' // Yellow
            break
          case '#f44336': // Red
            newColor = '#ffe599' // Yellow
            break
          case '#ffe599': // Yellow
            newColor = '#93c47d' // Green
            break
          case '#93c47d': // Green
            newColor = '#f44336' // Red
            break
          default: // Yellow
            newColor = '#ffe599'
            break
        }
        setTagColor(newColor)
        // update the database with the new tag color for the corresponding algorithm
        const algRef = ref(db, `${user?.uid}/${type}/${alg.name}`)
        get(algRef).then((snapShot) => {
          if (snapShot.val() === null) {
            set(algRef, { ...alg, tagColor: newColor })
          } else {
            set(child(algRef, 'tagColor'), newColor)
          }
        })
      }

      return (
        <div
          className='alg_box'
          key={index}
          style={{
            ...style,
            left: style.left + GUTTER_SIZE,
            top: style.top + GUTTER_SIZE,
            width: style.width - GUTTER_SIZE,
            height: style.height - GUTTER_SIZE,
          }}
        >
          <div className='upper_bar'>
            <LLDisplay
              alg={algs[index]}
              myFilter={myFilter}
              play='true'
              search={search}
              shouldUpdate={shouldUpdateLLDisplay(prevAlgs, algs)}
            />
            <div
              className='tag'
              style={{ backgroundColor: tagColor }}
              onClick={(e) => handleTagClick(e)}
            ></div>

            <div className='info_display'>
              <div>{algs[index].name}</div>
              <div className='alg_text'>{highlighting(algs[index].alg)}</div>
              <Tip index={index} alg={alg} />
            </div>
          </div>
          <div className='bottom_bar'></div>
        </div>
      )
    },
    (prevProps, nextProps) => {
      return prevProps.alg === nextProps.alg
    }
  )

  return (
    <div className='algorithms'>
      <form className='search_container'>
        <input
          className='search'
          placeholder='...Search'
          onChange={(e) => {
            setSearch(e.target.value)
          }}
        />
      </form>
      <div className='button_container'>
        {filterButtons.map((button) => (
          <button
            key={button}
            className={
              activeFilter === button ? 'button_filter active' : 'button_filter'
            }
            id={button}
            onClick={handleClick}
          >
            {button.toUpperCase()}
          </button>
        ))}
      </div>
      <div className='button_container'>
        {filterStates.map((button) => (
          <button
            key={button}
            className={
              tagFilters.includes(filterColors[button])
                ? 'button_filter active bounce-in'
                : 'button_filter'
            }
            id={button}
            onClick={handleClick}
          >
            {button.toUpperCase()}
          </button>
        ))}
      </div>
      <div className='alg_container'>
        <List
          height={600} // Adjust this value based on your container's height
          itemCount={algs.length}
          itemSize={150} // Adjust this value based on your item's height
          width={'100%'}
        >
          {({ index, style }) => (
            <Row alg={algs[index]} index={index} style={style} />
          )}
        </List>
      </div>
    </div>
  )
}
