import Sidebar from './Components/Sidebar'
import Main from './Pages/Main'
import Header from './Pages/Header'
import './App.css'

function App() {
  return (
      <div className='appContainer'>
        <Header />
        <Main />
        <Sidebar />
      </div>
  )
}

export default App
