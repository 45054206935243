import React from 'react'
import '../css/OptionsMenu.css'

export default function OptionsMenu() {
  return (
    <div className='optionsMenu-container bounce-in-bottom'>
      <div>Options</div>
    </div>
  )
}
