import {
  AuthErrorCodes,
  getAuth,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import { app } from '../Services/firebase'

const auth = getAuth(app)

// Create new user
export const signUp = (displayName, email, password) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // User signed up
      userCredential.user.updateProfile({
        displayName: displayName,
      })
    })
    .catch((err) => {
      // Handle errors
      if (err.code === AuthErrorCodes.WEAK_PASSWORD) {
        alert("Sorry your password is too weak, let's make that safer")
      } else if (err.code === AuthErrorCodes.EMAIL_EXISTS) {
        alert("You're already registered!")
      } else {
        console.log(err.code)
        alert(err.code)
      }
    })
}

// sign in with email and password
export const signIn = (email, password) => {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // The signed-in user info
      // const user = userCredential.user
    })
    .catch((error) => {
      // Handle errors
      if (
        error.code === AuthErrorCodes.INVALID_PASSWORD ||
        error.code === AuthErrorCodes.USER_DELETED ||
        error.code === AuthErrorCodes.INVALID_EMAIL
      ) {
        alert('The email address or password is incorrect')
      } else {
        alert(error.code)
        console.log(error.code)
      }
    })
}

//Sign in with google
export const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider()
  return signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result)
      const token = credential.accessToken
      // The signed-in user info
      const user = result.user
      console.log(user, token, credential)
    })
    .catch((error) => {
      // Handle errors
      console.log(error)
    })
}

//Sign in with Facebook
export const signInWithFacebook = () => {
  const provider = new FacebookAuthProvider()

  return signInWithPopup(auth, provider)
    .then((result) => {
      const credential = FacebookAuthProvider.credentialFromResult(result)
      const token = credential.accessToken
      // The signed-in user info
      const user = result.user
      console.log(user, token, credential)
    })
    .catch((error) => {
      // Handle errors
      console.log(error)
    })
}

// sign out function
export const signOutUser = () => {
  signOut(auth)
}
