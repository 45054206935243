import React from 'react'
import { useState, useEffect } from 'react'
import scrambleIt from '../Components/Scramble'
import { Stopwatch } from '../Components/Stopwatch'
import LatestTimes from '../Components/LatestTimes'
import AlgDisplay from '../Components/AlgDisplay'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import '../css/Timer.css'
import { useAuthState } from 'react-firebase-hooks/auth'
import { db, auth } from '../Services/firebase'
// import MicrophoneVolumeChecker from '../Services/microphone'
import Graph from '../Components/Graph'
import { ref, onValue } from 'firebase/database'

// import Chart from '../Components/Chart'

function Timer() {
  const [scramble, setScramble] = useState('')
  const [timerActive, setTimerActive] = useState(false)
  const [timeLogged, setTimeLogged] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)
  const [filter, setFilter] = useState('Latest Times')
  const [timeList, setTimeList] = useState()
  const [user, loading] = useAuthState(auth)

  // On mount generate scramble
  useEffect(() => {
    generateScramble()
  }, [])

  // On mount, get the latest times
  useEffect(() => {
    let isMounted = true
    if (user !== null) {
      const timeRef = ref(db, 'users/' + user.uid + '/times')
      onValue(timeRef, (snapshot) => {
        if (isMounted) {
          const newTimeList = []
          for (let key in snapshot.val()) {
            newTimeList.push({ ...snapshot.val()[key], id: key })
            setTimeList(newTimeList)
          }
        }
      })
    }
    return () => {
      isMounted = false
    }
  }, [user, timeLogged])

  useEffect(() => {
    timeLogged === 'Time logged' && generateScramble()
  }, [timeLogged])

  function handleBestTimesClick() {
    toggleDropdown()
    setFilter('Best Times')
  }

  function handleLatestTimesClick() {
    toggleDropdown()
    setFilter('Latest Times')
  }
  // Function to return a new scramble
  function generateScramble() {
    setScramble(scrambleIt(1))
  }

  function toggleDropdown() {
    setShowDropdown(!showDropdown)
  }

  const DropdownMenu = () => (
    <div className='timer-options-dropdown bounce-in'>
      <div className='timer-options-item' onClick={handleBestTimesClick}>
        Best Times
      </div>
      <div className='timer-options-item' onClick={handleLatestTimesClick}>
        Latest Times
      </div>
    </div>
  )

  return (
    <div className='timerContainer'>
      {timerActive && <div className='screenBlock'></div>}
      {/* <MicrophoneVolumeChecker /> */}
      <div className='scramble'>
        <AlgDisplay alg={scramble} />
      </div>
      <Stopwatch
        currentScramble={scramble}
        setTimeStatus={setTimeLogged}
        setTimerActive={setTimerActive}
      />
      <div className='cards'>
        <Graph times={timeList} filter={filter} />
        {/* <Chart /> */}
        <div className='timer-options' onClick={toggleDropdown}>
          {filter}
          {showDropdown ? <AiFillCaretUp /> : <AiFillCaretDown />}
          {showDropdown && <DropdownMenu />}
        </div>
        <LatestTimes status={timeLogged} timeFilter={filter} />
      </div>
    </div>
  )
}

export default Timer
